import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { mask } from 'remask';
//
import { validaCpf } from '../../functions/validaCpf';
import { validaCnpj } from '../../functions/validaCnpj';
//
import apiPlay from '../../services/apiPlay';
import apiCep from '../../services/apiCep';
//
import Checkbox from '../Checkbox';
import Input from '../Input';
import Botao from '../Botao';
import Row from '../Row';
//
import HipercardImg from '../../assets/bandeira_hipercard.png';
import DiscoverImg from '../../assets/bandeira_discover.png';
import DinnersImg from '../../assets/bandeira_dinners.png';
import MasterImg from '../../assets/bandeira_master.png';
import SslImg from '../../assets/site_seguro_imagem.png';
import VaziaImg from '../../assets/bandeira_vazia.png';
import AmexImg from '../../assets/bandeira_amex.png';
import VisaImg from '../../assets/bandeira_visa.png';
import EloImg from '../../assets/bandeira_elo.png';
//
import styles from './TelaRecorrencia.module.css';

export default function TelaRecorrencia({ fatura, payId, isRecActive }) {
	//STATES
	const [loadingPag, setLoadingPag] = useState(false);

	const [emailTitular, setEmailTitular] = useState('');
	const [cep, setCep] = useState('');
	const [endereco, setEndereco] = useState('');
	const [cpfCnpj, setCpfCnpj] = useState('');
	const [numCartao, setNumCartao] = useState('');
	const [nomeCartao, setNomeCartao] = useState('');
	const [mesVencimento, setmesVencimento] = useState('');
	const [anoVencimento, setAnoVencimento] = useState('');
	const [cvv, setCvv] = useState('');
	const [pagarAgora, setPagarAgora] = useState(false);

	const cardId = '1';
	const anoAtual = new Date().getFullYear().toString();

	//FUNCTION

	function ativaRecorrencia(e) {
		e && e.preventDefault();

		setLoadingPag(true);

		let dados = {
			cpf: cpfCnpj,
			statusrec: true,
			msisdn: fatura.msisdn,
		};

		apiPlay
			.post('/api/recorrencia/statusrecorrencia', dados)
			.then((response) => {
				console.log('Recorrencia Ativada');
				toast.success('Recorrencia Ativada! Em alguns minutos sua linha será recarregada.');
				setLoadingPag(false);

				setTimeout(() => {
					window.location.reload();
				}, 5000);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function desativaRecorrencia() {
		setLoadingPag(true);

		let dados = {
			msisdn: fatura.msisdn,
			cpf: fatura.cpf,
			statusrec: false,
		};

		apiPlay
			.post('/api/recorrencia/statusrecorrencia', dados)
			.then((response) => {
				console.log('Recorrencia Desativada');
				toast.info('Recorrencia Desativada! Em alguns minutos sua linha será recarregada.');
				setLoadingPag(false);

				setTimeout(() => {
					window.location.reload();
				}, 5000);
			})
			.catch((error) => {
				console.log(error);
				toast.error('Erro ao desativar recorrência');
				setLoadingPag(false);
			});
	}

	function realizaPagamento() {
		setLoadingPag(true);

		let dados = {
			payid: payId,
			pagacartao: '1',
			nomeCartao: nomeCartao,
			numero: numCartao,
			expirames: mesVencimento,
			expiraano: '20' + anoVencimento,
			ccv: cvv,
			nome: '',
			email: '',
			cpfCnpj: cpfCnpj,
			postalCode: '',
			endereconumero: '',
			enderecocomplemento: '',
			telefone: fatura.msisdn,
			telefonecelular: '',
		};

		apiPlay.post('/api/asaasfatura', dados).then((response) => {
			if (response.data.status === 'CONFIRMED') {
				toast.success('Pagamento Efetuado! Em alguns minutos sua linha será recarregada.');
				ativaRecorrencia();
			}

			if (response.data.status !== 'CONFIRMED') {
				console.log(response.data);
				toast.error('Erro ao efetuar pagamento');
				setLoadingPag(false);
			}
		});
	}

	function addCard(e) {
		e.preventDefault();

		setLoadingPag(true);

		let dados = {
			name: nomeCartao,
			cpf: fatura.cpf,
			cpfcartao: cpfCnpj,
			numerocartao: numCartao,
			expirames: mesVencimento,
			expiraano: '20' + anoVencimento,
			ccv: cvv,
			email: emailTitular,
			telefone: fatura.msisdn,
			cep: cep,
			endereco: endereco,
			card_id: cardId,
		};

		apiPlay
			.post('/api/recorrencia/cadastraCartao', dados)
			.then((response) => {
				pagarAgora ? realizaPagamento() : ativaRecorrencia();
			})
			.catch((error) => {
				console.log(error);
				toast.error('Erro ao adicionar cartão');
				setLoadingPag(false);
			});
	}

	//CICLOS
	useEffect(() => {
		function buscaCEP() {
			apiCep
				.get('/' + cep + '/json')
				.then((response) => {
					const res = response.data;

					setEndereco(`${res.uf} ${res.localidade} ${res.bairro} ${res.logradouro}`);
				})
				.catch((error) => {
					console.log(error);
				});
		}

		if (cep.length === 8) {
			buscaCEP();
		}
	}, [cep]);

	//BODY
	return (
		<section className={styles['container']}>
			{isRecActive && (
				<>
					<span className={styles['title']}>
						Você está com a recorrência ativada para esta linha
					</span>

					<div className={styles['form-container']}>
						<Botao
							text='Desativar Cobrança Recorrente'
							onClick={() => {
								let confirmou = window.confirm('Deseja desativar a cobrança recorrente?');
								if (confirmou) {
									desativaRecorrencia();
								}
							}}
							loading={loadingPag}
							color='error-g'
							style={{ marginTop: '2rem' }}
						/>
					</div>
				</>
			)}

			{!isRecActive && (
				<>
					<span className={styles['title']}>
						Informe os dados do cartão para finalizar o pagamento
					</span>

					<div className={styles['form-container']}>
						<form className={styles['form']} onSubmit={addCard}>
							<Row style={{ flexWrap: 'wrap', justifyContent: 'center', margin: '0.5rem' }}>
								<img className={styles['bandeiras']} src={MasterImg} alt='bandeira-master' />
								<img className={styles['bandeiras']} src={VisaImg} alt='bandeira-visa' />
								<img className={styles['bandeiras']} src={AmexImg} alt='bandeira-amex' />
								<img className={styles['bandeiras']} src={EloImg} alt='bandeira-elo' />
								<img className={styles['bandeiras']} src={DinnersImg} alt='bandeira-dinners' />
								<img className={styles['bandeiras']} src={DiscoverImg} alt='bandeira-discover' />
								<img className={styles['bandeiras']} src={HipercardImg} alt='bandeira-hipercard' />
							</Row>

							<span className={styles['subtitle']}>Dados Cartão</span>

							<Row style={{ position: 'relative' }}>
								<Input
									value={mask(numCartao, ['9999 9999 9999 9999'])}
									onChange={(e) => {
										setNumCartao(mask(e.target.value, ['9999999999999999']));
									}}
									label='Número do cartão'
									required
								/>
								<img className={styles['bandeira-img']} src={VaziaImg} alt='bandeira-vazia' />
								{numCartao.startsWith('5') && (
									<img className={styles['bandeira-img']} src={MasterImg} alt='bandeira-master' />
								)}
								{numCartao.startsWith('4') && (
									<img className={styles['bandeira-img']} src={VisaImg} alt='bandeira-visa' />
								)}
								{(numCartao.startsWith('34') || numCartao.startsWith('37')) && (
									<img className={styles['bandeira-img']} src={AmexImg} alt='bandeira-amex' />
								)}
								{(numCartao.startsWith('36') ||
									numCartao.startsWith('38') ||
									numCartao.startsWith('301') ||
									numCartao.startsWith('305')) && (
										<img className={styles['bandeira-img']} src={EloImg} alt='bandeira-elo' />
									)}
								{(numCartao.startsWith('60') || numCartao.startsWith('3841')) && (
									<img
										className={styles['bandeira-img']}
										src={HipercardImg}
										alt='bandeira-hipercard'
									/>
								)}
								{(numCartao.startsWith('6011') || numCartao.startsWith('65')) && (
									<img
										className={styles['bandeira-img']}
										src={DiscoverImg}
										alt='bandeira-discover'
									/>
								)}
							</Row>

							<Row>
								<Input
									value={nomeCartao}
									onChange={(e) => {
										setNomeCartao(e.target.value.toUpperCase());
									}}
									label='Nome (Igual no Cartão)'
									required
								/>
							</Row>

							<Row>
								<Input
									value={mesVencimento}
									onChange={(e) => {
										setmesVencimento(mask(e.target.value, ['99']));
									}}
									label='Mês'
									required
								/>

								<Input
									value={anoVencimento}
									onChange={(e) => {
										setAnoVencimento(mask(e.target.value, ['99']));
									}}
									label='Ano'
									required
								/>
							</Row>

							<Row>
								<Input
									value={cvv}
									onChange={(e) => {
										setCvv(mask(e.target.value, ['9999']));
									}}
									label='CVV (Código de Segurança)'
									required
								/>
							</Row>

							<Row>
								<Input
									value={mask(cpfCnpj, ['999.999.999-99', '99.999.999/9999-99'])}
									onChange={(e) => {
										setCpfCnpj(mask(e.target.value, ['99999999999', '99999999999999']));
									}}
									label='CPF ou CNPJ do Titular do Cartão'
									required
								/>
							</Row>

							<span className={styles['subtitle']}>Dados Pessoais</span>

							<Row>
								<Input
									type='email'
									value={emailTitular}
									onChange={(e) => {
										setEmailTitular(e.target.value);
									}}
									label='Email'
									required
								/>
							</Row>

							<Row>
								<Input
									type='tel'
									value={mask(cep, ['99999-999'])}
									onChange={(e) => {
										setCep(mask(e.target.value, ['99999999']));
									}}
									label='CEP'
									required
								/>
							</Row>

							<Checkbox
								id='PagarAgora'
								label='Pagar Fatura Atual?'
								checked={pagarAgora}
								onChange={() => setPagarAgora((prev) => !prev)}
							/>

							<Row>
								<Botao
									type='submit'
									text='Confirmar'
									style={{ width: '100%' }}
									loading={loadingPag}
									disabled={
										mesVencimento > '12' ||
										mesVencimento <= '0' ||
										anoVencimento < anoAtual ||
										(cpfCnpj.length > 11 && !validaCnpj(cpfCnpj || '00000000000000')) ||
										(cpfCnpj.length <= 11 && !validaCpf(cpfCnpj || '00000000000'))
									}
								/>
							</Row>
						</form>

						<div className={styles['ssl']}>
							<img src={SslImg} alt='site-seguro' />

							<span className={styles['txt-termos']}>
								<b>TERMOS E CONDIÇÕES</b>: Autorizo a{' '}
								<b>
									{fatura.nomeempresa?.toUpperCase()} | CNPJ{' '}
									{fatura.cnpj && mask(fatura.cnpj, ['99.999.999/9999-99'])}
								</b>{' '}
								a debitar em meu Cartão de Crédito o valor correspondente à <b>MENSALIDADE</b> dos
								compromissos da operadora móvel. Comprometo-me, desde já, a manter limite suficiente
								para o referido débito, declarando ciência de que a não liquidação do compromisso
								por insuficiência de limite na data do vencimento, não isenta a obrigação do
								pagamento. Em caso de dúvidas ou reclamação sobre datas de vencimento ou valores,
								devo solicitar esclarecimentos diretamente à empresa. Estou ciente que a Autorização
								de Débito pode ser cancelada por solicitação da {fatura.nomeempresa?.toUpperCase()}{' '}
								ou por mim, nos canais dos chats. Estou ciente que o cancelamento do débito
								automático não implica necessariamente no cancelamento do contrato com a{' '}
								{fatura.nomeempresa?.toUpperCase()}.
							</span>
						</div>
					</div>
				</>
			)}
		</section>
	);
}
